import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { useQuery } from "@tanstack/react-query";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { DataTableToolbar } from "@/components/table/data-table-toolbar.tsx";
import { DataTableFacetedFilter } from "@/components/table/data-table-faceted-filter.tsx";
import { DataTableSearch } from "@/components/table/data-table-search.tsx";
import { columns } from "@/pages/invoices/components/columns-received.tsx";

export function ReceivedDocuments() {
  const { data: invoice } = useQuery<{
    invoices: Array<{
      id: string;
      status: string;
      type: "product" | "service";
      issuer: {
        document: string;
        name?: string;
      };
      recipient: {
        document: string;
        name: string;
      };
      issuedAt: string;
      approvalStatus: string;
    }>;
  }>({
    queryKey: ["/invoices?type=received"],
    refetchInterval: 15000,
  });

  const table = useGenericTable({
    columns: columns,
    data: invoice?.invoices,
  });

  return (
    <div className="space-y-4">
      <DataTableToolbar table={table}>
        {table.getColumn("type") && (
          <DataTableFacetedFilter
            column={table.getColumn("type")}
            title="Tipo da Nota"
            options={[
              {
                label: "Serviço",
                value: "service",
              },
              {
                label: "Produto",
                value: "product",
              },
            ]}
          />
        )}
        <DataTableFacetedFilter
          title="Status da Aprovação"
          column={table.getColumn("status_internal")}
          options={[
            {
              label: "Análise Pendente",
              value: "pending",
            },
            {
              label: "Aprovada",
              value: "approved",
            },
            {
              label: "Rejeitada",
              value: "rejected",
            },
          ]}
        />
        <DataTableSearch
          placeholder="Emitente"
          column={table.getColumn("issuer")!}
        />
        <DataTableSearch
          placeholder="Tomador"
          column={table.getColumn("recipient")!}
        />
      </DataTableToolbar>
      <div className="rounded-md border">
        <DataTable table={table} />
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
