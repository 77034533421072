import { useQuery } from "@tanstack/react-query";
import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Link } from "react-router-dom";
import { PackagePlus } from "lucide-react";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { vendorsTableColumns } from "@/pages/vendors/components/table/vendors-table-columns.tsx";

export function ViewVendors() {
  const { data } = useQuery<{
    vendors: Array<{
      id: string;
      name: string;
      document: string;
    }>;
  }>({
    queryKey: ["/vendors"],
    refetchInterval: 60000,
  });

  const table = useGenericTable({
    columns: vendorsTableColumns,
    data: data?.vendors || null,
  });

  return (
    <div>
      <Button asChild={true}>
        <Link to="/vendors/new">
          <PackagePlus size={20} className="mr-2" />
          Cadastrar novo fornecedor
        </Link>
      </Button>
      <div className="space-y-4 mt-4">
        <div className="rounded-md border">
          <DataTable table={table} />
        </div>
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}
