import { Link } from "react-router-dom";
import { MapPinOff } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";

export function InvalidRoute() {
  return (
    <div
      className={"flex flex-col items-center justify-center h-screen gap-y-4"}
    >
      <picture>
        <source
          srcSet="/logo/twokei_black.png"
          media="(prefers-color-scheme: dark)"
        />
        <img
          src="/logo/twokei_white.png"
          alt="Twokei Logo"
          className="h-auto w-64"
        />
      </picture>
      <div className={"text-center flex flex-col space-y-2"}>
        <h1 className={"text-2xl font-medium tracking-widest"}>
          <MapPinOff className={"inline-block mr-2 text-destructive"} />
          Página não encontrada
        </h1>
        <p className={"text-sm text-muted-foreground"}>
          Hmm... parece que a página que você está procurando não existe.
        </p>
        <Button variant={"outline"}>
          <Link to={"/"} className={"btn btn-primary"}>
            Voltar para a página inicial
          </Link>
        </Button>
      </div>
    </div>
  );
}
