import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import type { z } from "zod";
import type { UseFormReturn } from "react-hook-form";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { CalendarIcon, Check, ChevronsUpDown } from "lucide-react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { PercentageInput } from "@/components/validators/currency-input.tsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command.tsx";

export function ServiceBaseForm({
  form,
  companies,
}: {
  form: UseFormReturn<z.infer<typeof serviceInvoiceSchema>>;
  companies?: CompanyWithAddress[];
}) {
  const selectedCompany = () => {
    const company = companies?.find(
      (company) => company.document === form.getValues("issuer.document")
    );
    return `${company?.document} - ${company?.name}`;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Dados da Nota Fiscal de Serviços Eletrônica</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"grid grid-cols-4 gap-3"}>
          <FormField
            name="issuedAt"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Data de emissão</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Escolha uma data</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date > new Date() ||
                        date < new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.simplesNacionalOptant"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Optante pelo Simples Nacional</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value === "yes");
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="yes">Optante</SelectItem>
                    <SelectItem value="no">Não optante</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.specialRegimeTributary"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Regime tributário especial</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value === "yes");
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="1">
                      1 - Microempresa Individual
                    </SelectItem>
                    <SelectItem value="2">2 - Estimativa</SelectItem>
                    <SelectItem value="3">
                      3 - Sociedade de Profissionais
                    </SelectItem>
                    <SelectItem value="4">4 - Cooperativa</SelectItem>
                    <SelectItem value="5">5 - MEI Simples Nacional</SelectItem>
                    <SelectItem value="6">
                      6 - ME EPP Simples Nacional
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.culturalSupporter"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Incentivador cultural</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value === "yes");
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="yes">Sim</SelectItem>
                    <SelectItem value="no">Não</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.operationNature"
            render={({ field }) => (
              <FormItem className="w-full col-span-4">
                <FormLabel required>Natureza da operação</FormLabel>
                <Select onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="1">
                      1 - Tributação no município
                    </SelectItem>
                    <SelectItem value="2">
                      2 - Tributação fora do município
                    </SelectItem>
                    <SelectItem value="3">3 - Isenção</SelectItem>
                    <SelectItem value="4">4 - Imune</SelectItem>
                    <SelectItem value="5">
                      5 - Exigibilidade suspensa por decisão judicial
                    </SelectItem>
                    <SelectItem value="6">
                      6 - Exigibilidade suspensa por procedimento administrativo
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.document"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel required>CNPJ</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                          "w-full justify-between",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        <span>
                          {field.value
                            ? selectedCompany()
                            : "Selecione a empresa"}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-[300px] md:w-[700px] p-0 max-w-[500px] overflow-y-auto">
                    <Command>
                      <CommandInput placeholder="Procure a empresa..." />
                      <CommandEmpty>Nenhuma empresa encontrada.</CommandEmpty>
                      <CommandGroup>
                        {companies?.map((company) => (
                          <CommandItem
                            key={company.document}
                            onSelect={() => {
                              field.onChange(company.document);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                company.document === field.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {company.document} - {company.name}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.aliquot"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Aliquota</FormLabel>

                <FormControl>
                  <PercentageInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.issRetained"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Retenção do ISS na Fonte?</FormLabel>
                <Select
                  onValueChange={(value) => field.onChange(value === "yes")}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="yes">Sim</SelectItem>
                    <SelectItem value="no">Não</SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
