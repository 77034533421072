import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { successNfseColumns } from "@/pages/invoices/import/components/nfse/components/success-data-table-columns.tsx";
import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.tsx";
import { Label } from "@/components/ui/label.tsx";

interface SuccessDataTableProps {
  data: {
    status: "success" | "error";
    invoice: CreateServiceInvoice;
  }[];
}

export function SuccessDataTable({ data }: SuccessDataTableProps) {
  const table = useGenericTable({
    columns: successNfseColumns,
    data: data,
  });

  if (!data) return null;

  return (
    <div>
      <Label className="ml-0">{data.length} registro(s) com sucesso</Label>
      <div className="my-4">
        <ScrollArea className={"rounded-md border"}>
          <div className={"max-h-[450px]"}>
            <DataTable table={table} />
          </div>
          <ScrollBar orientation={"horizontal"} />
          <ScrollBar orientation={"vertical"} />
        </ScrollArea>
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}
