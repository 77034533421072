import { Outlet, useParams } from "react-router-dom";
import { useVendor } from "@/hooks/use-vendor.ts";
import { Loader2 } from "lucide-react";
import { Separator } from "@/components/ui/separator.tsx";
import { SidebarNav } from "@/components/sidebar-nav.tsx";

export function VendorEditLayout() {
  const { document } = useParams();
  const { data, isError, error } = useVendor(document);

  const sidebarNavItems = [
    { label: "Dados do Fornecedor", href: "edit" },
    { label: "Histórico", href: "history" },
  ];

  if (isError && error instanceof Error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-muted-foreground">{error.message}</p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="animate-spin h-8 w-8" />
      </div>
    );
  }

  return (
    <div className="space-y-6 px-10 pt-5 pb-16">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">
          {!isError && data.name}
        </h2>
        <p className="text-muted-foreground">
          Gerencie as informações do fornecedor
        </p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/5">
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1 lg:max-w-2xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
