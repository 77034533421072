import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { Label } from "@/components/ui/label.tsx";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { errorsNfseColumns } from "@/pages/invoices/import/components/nfse/components/errors-data-table-columns.tsx";

interface ErrorDataTableProps {
  data: {
    status: "success" | "error";
    errors: string[];
    line: number;
    csvData: Record<string, string>;
  }[];
}

export function ErrorsDataTable({ data }: ErrorDataTableProps) {
  const table = useGenericTable({
    columns: errorsNfseColumns,
    data: data,
  });

  if (!data) return null;

  return (
    <div>
      <Label>{data.length} registro(s) com erros</Label>

      <div className={"space-y-4"}>
        <ScrollArea className={"rounded-md border"}>
          <div className={"max-h-[450px]"}>
            <DataTable table={table} />
          </div>
          <ScrollBar orientation={"horizontal"} />
          <ScrollBar orientation={"vertical"} />
        </ScrollArea>
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}
