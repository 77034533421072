import { Separator } from "@/components/ui/separator.tsx";
import { Outlet } from "react-router-dom";

export function InvoicesBatchesLayout() {
  return (
    <div className="space-y-6 px-10 pt-5 pb-16">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Lotes</h2>
        <p className="text-muted-foreground">
          Veja todas as emissões de notas fiscais em lote que estão na fila para
          processamento, em processamento ou concluídas.
        </p>
      </div>
      <Separator className="my-6" />
      <Outlet />
    </div>
  );
}
