import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form.tsx";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input.tsx";
import { ProcessedNfseFile } from "@/pages/invoices/import/components/nfse/processed-nfse-file.tsx";
import { ArrowBigDownDash } from "lucide-react";
import { toast } from "sonner";
import { csvFileParser } from "@/lib/csv-parser.ts";
import { onlyNumbers } from "@shared/format.ts";
import { useMutation } from "@tanstack/react-query";
import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import { parse } from "date-fns";
import { PendingButton } from "@/components/pending-button.tsx";
import { validateDocument } from "@shared/utils.ts";
import { isCNPJ } from "validation-br";

const schema = z.object({
  csvFile: z
    .custom<File | undefined>()
    .refine((value) => value && value.name.endsWith(".csv"), {
      message: "Por favor, adicione um arquivo no format CSV válido.",
    }),
});

type FileUpload = z.infer<typeof schema>;

const transformCurrency = (input: string) => {
  const trimmedInput = input.trim();

  if (/^\d+(\.\d+)?$/.test(trimmedInput)) {
    return trimmedInput;
  }

  const withoutThousandSeparators = trimmedInput.replace(/\./g, "");
  return withoutThousandSeparators.replace(/,/g, ".");
};

const csvSchema = z
  .object({
    data_emissao: z
      .string()
      .min(1, "Data de emissão é obrigatória")
      .transform((value) =>
        parse(value, "dd/MM/yyyy", new Date()).toISOString()
      ),

    natureza_operacao: z.string().min(1, "Natureza da operação é obrigatória"),
    regime_especial_tributario: z.string(),
    optante_simples_nacional: z
      .string()
      .min(1, "Optante pelo Simples Nacional precisa ser informado")
      .refine((value) => ["sim", "não"].includes(value.toLowerCase()), {
        message:
          "O campo optante pelo Simples Nacional precisa ser informado como sim ou não",
      }),
    incentivador_cultural: z.string(),

    prestador_documento: z
      .string()
      .min(1, "O documento do prestador é obrigatório (CPF ou CNPJ)")
      .transform(onlyNumbers)
      .refine(validateDocument, {
        message: "O documento do prestador é inválido",
      }),

    tomador_documento: z
      .string()
      .min(1, "O documento do tomador é obrigatório")
      .transform(onlyNumbers)
      .refine(validateDocument, {
        message: "O documento do tomador é inválido",
      }),
    tomador_inscricao_municipal: z.string(),
    tomador_inscricao_estadual: z.string(),
    tomador_razao_social: z
      .string()
      .min(1, "Razão social do tomador é obrigatório"),
    tomador_telefone: z.string(),
    tomador_email: z.string(),
    tomador_logradouro: z
      .string()
      .min(1, "Logradouro do tomador é obrigatório"),
    tomador_tipo_logradouro: z
      .string()
      .min(1, "Tipo de logradouro é obrigatório (rua, avenida, etc)"),
    tomador_numero: z
      .string()
      .min(1, "Número do logradouro é obrigatório. Use S/N se nao tiver"),
    tomador_complemento: z.string(),
    tomador_bairro: z.string().min(1, "Bairro do tomador é obrigatório"),
    tomador_codigo_do_municipio: z
      .string()
      .min(1, "Código do município do tomador é obrigatório"),
    tomador_uf: z.string().min(1, "UF do tomador é obrigatório"),
    tomador_cep: z.string().min(1, "CEP do tomador é obrigatório"),

    servico_valor_servicos: z
      .string()
      .min(1, "Valor dos serviços é obrigatório")
      .transform(transformCurrency),
    servico_valor_deducoes: z
      .string()
      .default("0")
      .transform(transformCurrency),
    servico_valor_pis: z.string().default("0").transform(transformCurrency),
    servico_valor_cofins: z.string().default("0").transform(transformCurrency),
    servico_valor_inss: z.string().default("0").transform(transformCurrency),
    servico_valor_ir: z.string().default("0").transform(transformCurrency),
    servico_valor_csll: z.string().default("0").transform(transformCurrency),
    servico_iss_retido: z
      .string()
      .min(1, "Retenção ISS é obrigatória (sim/não)")
      .refine((value) => ["sim", "não"].includes(value.toLowerCase()), {
        message:
          "O campo Serviço ISS Retido precisa ser informado como sim ou não",
      }),
    servico_valor_iss: z.string().default("0").transform(transformCurrency),
    servico_valor_iss_retido: z
      .string()
      .default("0")
      .transform(transformCurrency),
    servico_outras_retencoes: z
      .string()
      .default("0")
      .transform(transformCurrency),
    servico_base_calculo: z.string().default("0").transform(transformCurrency),
    servico_aliquota: z.string(),
    servico_desconto_incondicionado: z
      .string()
      .default("0")
      .transform(transformCurrency),
    servico_desconto_condicionado: z
      .string()
      .default("0")
      .transform(transformCurrency),
    servico_item_lista_servico: z.string(),
    servico_codigo_cnae: z.string(),
    servico_codigo_tributario_municipio: z.string(),
    servico_discriminacao: z.string(),
  })
  .transform((data) => ({
    issuedAt: data.data_emissao,
    issuer: {
      document: data.prestador_documento,
      operationNature: data.natureza_operacao,
      specialRegimeTributary: data.regime_especial_tributario,
      simplesNacionalOptant: data.optante_simples_nacional === "sim",
      culturalSupporter: data.incentivador_cultural === "sim",
    },
    recipient: {
      document: data.tomador_documento,
      documentType: (isCNPJ(data.tomador_documento) ? "CNPJ" : "CPF") as
        | "CPF"
        | "CNPJ",
      municipalRegistration: data.tomador_inscricao_municipal,
      stateRegistration: data.tomador_inscricao_estadual,
      companyName: data.tomador_razao_social,
      email: data.tomador_email,
      cellphone: data.tomador_telefone,
      address: {
        street: data.tomador_tipo_logradouro + " " + data.tomador_logradouro,
        number: data.tomador_numero,
        complement: data.tomador_complemento,
        district: data.tomador_bairro,
        cityCode: data.tomador_codigo_do_municipio,
        state: data.tomador_uf,
        zipCode: data.tomador_cep,
      },
    },
    service: {
      value: data.servico_valor_servicos,
      deductions: data.servico_valor_deducoes,
      pis: data.servico_valor_pis,
      cofins: data.servico_valor_cofins,
      inss: data.servico_valor_inss,
      ir: data.servico_valor_ir,
      csll: data.servico_valor_csll,
      issRetained: data.servico_iss_retido === "sim",
      issValue: data.servico_valor_iss,
      issValueRetained: data.servico_valor_iss_retido,
      otherRetentions: data.servico_outras_retencoes,
      baseValue: data.servico_base_calculo,
      aliquot: data.servico_aliquota,
      unconditionalDiscount: data.servico_desconto_incondicionado,
      conditionalDiscount: data.servico_desconto_condicionado,
      item: data.servico_item_lista_servico,
      cnae: data.servico_codigo_cnae,
      municipalTaxCode: data.servico_codigo_tributario_municipio,
      description: data.servico_discriminacao,
    },
  }));

export function ImportServiceDocument() {
  const { mutate, data, isPending } = useMutation({
    mutationKey: ["load-csv"],
    mutationFn: async (
      file: File
    ): Promise<{
      fileName: string;
      dataSuccess: {
        status: "success";
        invoice: CreateServiceInvoice;
      }[];
      dataErrors: {
        status: "error";
        errors: string[];
        line: number;
        csvData: Record<string, string>;
      }[];
    }> => {
      const parsedCsvFile = await csvFileParser(file, {
        header: true,
      });

      if (!parsedCsvFile.data.length) {
        return {
          fileName: file.name,
          dataSuccess: [],
          dataErrors: [
            {
              status: "error",
              errors: ["O arquivo não contém dados válidos (nenhum registro)."],
              line: 1,
              csvData: {},
            },
          ],
        };
      }

      const result = parsedCsvFile.data.map((invoice, index) => {
        const parsedResult = csvSchema.safeParse(invoice);

        if (!parsedResult.success) {
          return {
            status: "error",
            errors: parsedResult.error?.issues.map((issue) => {
              if (issue.message.toLowerCase().includes("required")) {
                return `A coluna ${issue.path.join(".")} é obrigatória`;
              }

              return issue.message;
            }),
            csvData: parsedCsvFile.data[index],
            line: index + 1,
          } as const;
        }

        return {
          invoice: parsedResult.data,
          status: "success",
        } as const;
      });

      const success = result.filter((invoice) => invoice.status === "success");
      const errors = result.filter((invoice) => invoice.status === "error");

      return {
        fileName: file.name,
        dataSuccess: success,
        dataErrors: errors,
      };
    },
    onError: () => {
      toast.error(
        "Ocorreu um erro ao carregar o arquivo CSV. Por favor, verifique todos os dados informados e tente novamente."
      );
    },
  });

  const form = useForm<FileUpload>({
    resolver: zodResolver(schema),
  });

  const submit = async ({ csvFile }: FileUpload) => {
    if (!csvFile) {
      toast.error("Por favor, selecione um arquivo CSV válido.");
      return;
    }

    mutate(csvFile);
  };

  return (
    <div className={"space-y-4"}>
      <Card>
        <CardHeader>
          <CardTitle>Importar arquivo CSV para emissão de NFSes</CardTitle>
          <CardDescription>
            Importe um arquivo CSV com os dados para realizar a emissão em lote.
            O modelo do arquivo pode ser encontrado{" "}
            <a
              href={"https://cdn.twokei.com/docs/nfse-template.xlsx"}
              className={"underline"}
              target={"_blank"}
            >
              aqui.
            </a>
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)} className="space-y-3">
              <div className={"flex w-full space-x-2"}>
                <FormField
                  control={form.control}
                  name="csvFile"
                  render={({ field }) => (
                    <FormItem className={"w-full"}>
                      <FormControl>
                        <Input
                          type="file"
                          accept=".csv"
                          disabled={isPending}
                          multiple={false}
                          onChange={async (e) => {
                            const file = e.target.files?.[0];
                            field.onChange(file ? file : undefined);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <PendingButton
                  pending={isPending}
                  disabled={!form.watch("csvFile") || isPending}
                >
                  {isPending ? "Enviando..." : "Enviar"}
                </PendingButton>
              </div>
            </form>
          </Form>
          {isPending && (
            <div className={"mt-2"}>
              <ArrowBigDownDash
                className={"inline-block animate-bounce mr-2"}
              />
              <span className={"font-medium text-sm tracking-wide"}>
                Estamos processando o arquivo.
              </span>
            </div>
          )}
        </CardContent>
      </Card>
      {!!data && (
        <ProcessedNfseFile
          success={data.dataSuccess}
          errors={data.dataErrors}
          fileName={data.fileName}
        />
      )}
    </div>
  );
}
