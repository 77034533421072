import { Outlet } from "react-router-dom";
import { useTitle } from "@/hooks/use-title.ts";
import { MobileNavbar } from "@/components/layout/navbar/mobile-navbar.tsx";
import { DesktopNavbar } from "@/components/layout/navbar/desktop-navbar.tsx";

export function Layout() {
  useTitle("Sistema");

  return (
    <main className="relative flex min-h-screen w-full flex-col overflow-hidden">
      <MobileNavbar />
      <DesktopNavbar />
      <div className="container py-5 flex-1 relative h-full flex-col items-center justify-center">
        <Outlet />
      </div>
    </main>
  );
}
