import { Form } from "@/components/ui/form.tsx";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import { zodResolver } from "@hookform/resolvers/zod";

import { Separator } from "@/components/ui/separator.tsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { useNavigate } from "react-router-dom";
import { PendingButton } from "@/components/pending-button.tsx";
import { ErrorBoundaryException } from "@/components/error-boundary/exception.tsx";
import { ErrorBoundary } from "@sentry/react";
import { ServiceBaseForm } from "@/pages/invoices/service/components/service-base-form.tsx";
import { RecipientForm } from "@/pages/invoices/service/components/recipient-form.tsx";
import { ServiceForm } from "@/pages/invoices/service/components/service-form.tsx";
import { TaxForm } from "@/pages/invoices/service/components/tax-form.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";

type CreateNfse = z.infer<typeof serviceInvoiceSchema>;

export function CreateServiceInvoice() {
  const form = useForm<CreateNfse>({
    resolver: zodResolver(serviceInvoiceSchema),
    defaultValues: {
      issuedAt: new Date().toISOString(),
    },
  });

  const navigate = useNavigate();

  const { data } = useQuery<{
    companies: CompanyWithAddress[];
  }>({
    queryKey: ["/companies"],
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [`/invoices/sent/services`],
    mutationFn: (nfse: CreateNfse) => {
      return fetchApi(`/invoices/sent/services`, {
        method: "POST",
        data: nfse,
      });
    },
    onSuccess: () => {
      navigate("/invoices/sent");
    },
  });

  const submit = form.handleSubmit((data) => {
    mutate(data);
  });

  console.log(form.formState.errors);

  return (
    <ErrorBoundary fallback={ErrorBoundaryException}>
      <div className="space-y-6 px-10 pt-5 pb-16">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight ">
            Emitir Nota Fiscal de Serviços Eletrônica
          </h2>
          <p className="text-muted-foreground">
            Preencha os campos abaixo para emitir uma nota fiscal de serviços
            eletrônica.
          </p>
          <Separator />
        </div>
        <Form {...form}>
          <form onSubmit={submit} className="flex flex-col gap-y-3 mt-8">
            <ServiceBaseForm form={form} companies={data?.companies} />
            <RecipientForm form={form} />
            <ServiceForm form={form} companies={data?.companies} />
            <TaxForm form={form} />
            <div className="flex justify-end">
              <PendingButton pending={isPending} type="submit">
                Emitir
              </PendingButton>
            </div>
          </form>
        </Form>
      </div>
    </ErrorBoundary>
  );
}
