import { Button } from "@/components/ui/button.tsx";
import { ArrowUp, FilePlus, FileScan, FileStack } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Link } from "react-router-dom";
import { useState } from "react";
import { cn } from "@/lib/utils.ts";

const importOptions = [
  {
    label: "Notas fiscais de serviços (NFS-e)",
    icon: <FilePlus size={16} className="mr-2" />,
    to: "/invoices/import/service",
  },
  {
    label: "Notas fiscais (NF-e)",
    icon: <FilePlus size={16} className="mr-2" />,
    to: "/invoices/import/product",
    disabled: true,
  },
];

const emitOptions = [
  {
    label: "Nota fiscal de serviço (NFS-e)",
    icon: <FilePlus size={16} className="mr-2" />,
    to: "/invoices/new/service",
  },
  {
    label: "Nota fiscal (NF-e)",
    icon: <FilePlus size={16} className="mr-2" />,
    to: "/invoices/new/product",
  },
];

export function InvoiceOptions() {
  const [importDropdownOpen, setImportDropdownOpen] = useState(false);
  const [emitDropdownOpen, setEmitDropdownOpen] = useState(false);

  return (
    <div className="flex space-x-6 mb-6 select-none">
      <DropdownMenu
        open={importDropdownOpen}
        onOpenChange={setImportDropdownOpen}
      >
        <DropdownMenuTrigger asChild>
          <Button type="button" variant="default" className="select-none">
            Emissão em lote{" "}
            <ArrowUp
              size={16}
              className={cn(
                "ml-2 transition-all",
                importDropdownOpen ? `rotate-180` : `rotate-0`
              )}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {importOptions.map((option, index) => (
            <Link to={option.disabled ? "" : option.to} key={option.to}>
              <DropdownMenuItem
                key={index}
                disabled={option.disabled}
                className="cursor-pointer"
              >
                {option.icon}
                {option.label}
              </DropdownMenuItem>
            </Link>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <DropdownMenu open={emitDropdownOpen} onOpenChange={setEmitDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button type="button" variant="default" className="select-none">
            <span>Emitir</span>
            <ArrowUp
              size={16}
              className={cn(
                "ml-2 transition-all",
                emitDropdownOpen ? `rotate-180` : `rotate-0`
              )}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {emitOptions.map((option, index) => (
            <Link key={option.to} to={option.to}>
              <DropdownMenuItem key={index} className="cursor-pointer">
                {option.icon}
                {option.label}
              </DropdownMenuItem>
            </Link>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <Link to={"/invoices/batches"}>
        <Button type="button" variant="outline">
          <FileStack size={16} className="mr-2" />
          Lotes
        </Button>
      </Link>

      <Button type="button" variant="outline" disabled={true}>
        <FileScan size={16} className="mr-2" />
        Relatório em CSV
      </Button>
    </div>
  );
}
