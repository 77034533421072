import { useState } from "react";
import {
  BookOpen,
  FileText,
  Settings,
  HelpCircle,
  Search,
  ArrowRight,
} from "lucide-react";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Link } from "react-router-dom";

interface BlogPost {
  id: string;
  title: string;
  description: string;
  category: "nfse";
}

const blogPosts: BlogPost[] = [
  {
    id: "goiania",
    title: "Como emitir NFSE em Goiânia",
    description:
      "Aprenda a emitir Nota Fiscal de Serviço Eletrônico (NFSE) individualmente ou em massa.",
    category: "nfse",
  },
  {
    id: "caldas-novas",
    title: "Como emitir NFSE em Caldas Novas",
    description:
      "Aprenda a emitir Nota Fiscal de Serviço Eletrônico (NFSE) individualmente ou em massa.",
    category: "nfse",
  },
];

const categories = ["NFSE"];

const getCategoryIcon = (category: string) => {
  switch (category) {
    case "guide":
      return <BookOpen className="w-4 h-4" />;
    case "tutorial":
      return <FileText className="w-4 h-4" />;
    case "faq":
      return <HelpCircle className="w-4 h-4" />;
    case "update":
      return <Settings className="w-4 h-4" />;
    default:
      return null;
  }
};

const getCategoryColor = (category: string) => {
  switch (category) {
    case "guide":
      return "bg-blue-100 text-blue-800";
    case "tutorial":
      return "bg-green-100 text-green-800";
    case "faq":
      return "bg-yellow-100 text-yellow-800";
    case "update":
      return "bg-purple-100 text-purple-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export function Blog() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("nfse");

  const filteredPosts = blogPosts.filter(
    (post) =>
      post.category === selectedCategory &&
      (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex gap-4">
        <div className="w-48">
          <Select onValueChange={setSelectedCategory} defaultValue="NFSE">
            <SelectTrigger>
              <SelectValue placeholder="Select category" />
            </SelectTrigger>
            <SelectContent>
              {categories.map((category) => (
                <SelectItem key={category} value={category}>
                  <span className="capitalize">{category}</span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <Input
            type="text"
            placeholder="Pesquisar na base de conhecimento..."
            className="pl-10 pr-4 py-2 w-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredPosts.map((post) => (
          <Card
            key={post.id}
            className="overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1 h-60"
          >
            <Link to={`${post.category}/${post.id}`} className="block h-full">
              <CardContent className="p-6 flex flex-col h-full relative">
                <Badge
                  variant="secondary"
                  className={`absolute top-2 right-2 flex items-center gap-1 ${getCategoryColor(post.category)} text-xs`}
                >
                  {getCategoryIcon(post.category)}
                  <span className="uppercase">{post.category}</span>
                </Badge>
                <h2 className="text-xl font-semibold mb-4 pr-8 text-pretty">
                  {post.title}
                </h2>
                <p className="text-gray-600 mb-4 line-clamp-3 flex-grow">
                  {post.description}
                </p>
                <div className="flex items-center justify-end text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200">
                  Ler Mais
                  <ArrowRight className="ml-1 h-4 w-4" />
                </div>
              </CardContent>
            </Link>
          </Card>
        ))}
      </div>
      {filteredPosts.length === 0 && (
        <div className="text-center text-gray-600 mt-8">
          Nenhum post encontrado com os critérios de pesquisa.
        </div>
        //   CASO TENHA DUVIDAS PROCURE A ADMIN OBG
      )}
    </div>
  );
}
