import { BatchesList } from "@/pages/invoices/batches/components/batch-list.tsx";
import { Check, Clock, Hourglass } from "lucide-react";

export function Batches() {
  return (
    <div className={"space-y-4"}>
      <BatchesList
        header={
          <>
            <p className={"text-xl font-medium tracking-tight"}>
              Em processamento
            </p>
            <Clock className={"size-5 text-orange-500"} />
          </>
        }
        batchType={"processing"}
      />
      <BatchesList
        header={
          <>
            <p className={"text-xl font-medium tracking-tight"}>Na fila</p>
            <Hourglass className={"size-5 text-primary"} />
          </>
        }
        batchType={"queued"}
      />
      <BatchesList
        header={
          <>
            <p className={"text-xl font-medium tracking-tight"}>Concluídos</p>
            <Check className={"size-5 text-green-500"} />
          </>
        }
        batchType={"finished"}
      />
    </div>
  );
}
