import {
  BatchCard,
  type BatchCardData,
} from "@/pages/invoices/batches/components/batch-card.tsx";
import { Eraser, FileX, Filter, Loader2 } from "lucide-react";
import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { type ReactNode } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { fetchApi } from "@/lib/api.ts";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { DatePicker } from "@/components/ui/date-picker.tsx";
import { useDebounce } from "@/hooks/use-debounce.tsx";
import { format } from "date-fns";

export function BatchesList({
  batchType,
  header,
}: {
  batchType: "processing" | "queued" | "finished";
  header: ReactNode;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get(`page_${batchType}`) ?? "1";
  const startDate = searchParams.get(`start_date_${batchType}`) ?? "";
  const endDate = searchParams.get(`end_date_${batchType}`) ?? "";
  const name = searchParams.get(`name_${batchType}`) ?? "";
  const perPage = 3;

  const debouncedName = useDebounce(name, 500);

  const { data, isLoading } = useQuery<{
    batches: BatchCardData[];
    total: number;
  }>({
    queryKey: [
      "invoices/batches",
      batchType,
      page,
      startDate,
      endDate,
      debouncedName,
      perPage,
    ],
    refetchInterval: 10000,
    queryFn: async () => {
      return fetchApi
        .get(
          `/invoices/batches?status=${batchType}&page=${page}&start=${startDate}&end=${endDate}&name=${debouncedName}&perPage=${perPage}`
        )
        .then((res) => res.data.data);
    },
    placeholderData: keepPreviousData,
  });

  if (!data || isLoading) {
    return (
      <div>
        <Loader2 className={"size-5 animate-spin"} />
      </div>
    );
  }

  console.log(data);

  const totalPages = Math.ceil(data.total / perPage);
  const hasNextPage = Number(page) < totalPages;
  const hasPreviousPage = page !== "1" && Number(page) > 1;
  const currentPage = Number(page);

  const isFiltered = name || startDate || endDate;

  const clearFilters = () => {
    setParams({
      [`name_${batchType}`]: undefined,
      [`start_date_${batchType}`]: undefined,
      [`end_date_${batchType}`]: undefined,
    });
  };

  const setParams = (params: Record<string, string | undefined>) => {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    setSearchParams(searchParams);
  };

  const BatchTotals = () => (
    <span>
      {data.total} {data.total !== 1 ? `lotes` : `lote`}
    </span>
  );

  const ResultInfo = () => {
    const noFilteredResult = data.total === 0 && isFiltered;

    return noFilteredResult ? (
      <p className={"text-sm text-muted-foreground"}>
        Nenhum resultado encontrado para os filtros selecionados
      </p>
    ) : (
      <p className={"text-sm text-muted-foreground"}>
        Não há lotes para exibir nesta seção
      </p>
    );
  };

  return (
    <div className={"rounded-md border px-4 py-2 space-y-2 min-h-[340px]"}>
      <div
        className={
          "flex flex-col flex-wrap md:flex-row justify-between items-center mb-4"
        }
      >
        <div className={"flex items-center gap-x-2"}>{header}</div>
        <div className={"flex items-center gap-x-2"}>
          <div
            className={
              "flex flex-col md:flex-row md:items-center gap-y-2 md:gap-x-2"
            }
          >
            {name && <Badge variant={"dashed"}>Nome: {name}</Badge>}
            {startDate && (
              <Badge variant={"dashed"}>
                Data inicial: {format(new Date(startDate), "PPP")}
              </Badge>
            )}
            {endDate && (
              <Badge variant={"dashed"}>
                Data final: {format(endDate, "PPP")}
              </Badge>
            )}
          </div>
          {isFiltered && (
            <Button size={"xs"} variant={"outline"} onClick={clearFilters}>
              <Eraser className={"size-5"} />
            </Button>
          )}
          <Popover>
            <PopoverTrigger asChild>
              <Button variant={"outline"} className={"gap-x-2"}>
                <Filter className={"size-5"} />
                <span>Filtrar</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              align={"end"}
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <div>
                <p className={"text-medium mb-1"}>Filtrar resultados</p>
                <p className={"text-xs text-muted-foreground mb-2"}>
                  Filtrar por nome, data inicial e data final
                </p>
                <Separator />
                <div className={"flex flex-col gap-2 mt-2"}>
                  <div>
                    <Label htmlFor="name" className={"text-sm"}>
                      Nome
                    </Label>
                    <Input
                      id={"name"}
                      value={name}
                      onChange={(e) =>
                        setParams({ [`name_${batchType}`]: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <Label htmlFor="name" className={"text-sm"}>
                      Data inicial
                    </Label>
                    <DatePicker
                      date={startDate ? new Date(startDate) : undefined}
                      setDate={(date) => {
                        setParams({
                          [`start_date_${batchType}`]: date?.toISOString(),
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Label htmlFor="name" className={"text-sm"}>
                      Data final
                    </Label>
                    <DatePicker
                      date={endDate ? new Date(endDate) : undefined}
                      setDate={(date) => {
                        setParams({
                          [`end_date_${batchType}`]: date?.toISOString(),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      {data.batches.length === 0 && (
        <div className={"flex items-center justify-center h-[200px] w-full"}>
          <div className={"flex flex-row items-center gap-x-2"}>
            <FileX className={"size-5"} />
            <ResultInfo />
          </div>
        </div>
      )}
      {data.batches.length > 0 && (
        <>
          <div
            className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"}
          >
            {data.batches.map((batch) => (
              <BatchCard key={batch.id} batchData={batch} />
            ))}
          </div>
          <div className={"flex justify-end px-3 py-1"}>
            <div className={"flex items-center space-x-2"}>
              <span className={"text-sm text-muted-foreground"}>
                <BatchTotals /> • Página {currentPage} de {totalPages}
              </span>
              <button
                disabled={!hasPreviousPage}
                type="button"
                className={
                  "bg-accent p-1 rounded-full hover:bg-accent/80 disabled:opacity-50 disabled:cursor-not-allowed"
                }
                onClick={() =>
                  setParams({
                    [`page_${batchType}`]: (parseInt(page) - 1).toString(),
                  })
                }
              >
                <ChevronLeftIcon className={"size-5"} />
              </button>
              <button
                type="button"
                className={
                  "bg-accent p-1 rounded-full hover:bg-accent/80 disabled:opacity-50 disabled:cursor-not-allowed"
                }
                onClick={() =>
                  setParams({
                    [`page_${batchType}`]: (parseInt(page) + 1).toString(),
                  })
                }
                disabled={!hasNextPage}
              >
                <ChevronRightIcon className={"size-5"} />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
